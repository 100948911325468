<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Módulo de Descarga
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <!-- <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  Data de Referência: {{ data }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  Revendedor: {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container> -->

      <!-- <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col> -->

      <v-container fluid class="pt-0">
        <v-col>
          <div class="box-table">
            <v-data-table
              :headers="headers"
              :items="arrGames"
              width="300px"
              flat
              dense
              @click:row="opemDadosJogos" > 
              <template v-slot:top>
                <!-- <BrToolBar @search="buscar" :configFilter="{ getItens: getItens, jsonData: true }" /> -->
              </template>

              <template v-slot:item.value="{ item }">
                {{ item.value | currency }}
              </template>

             <!--  <template v-slot:item.gameNumber="{ item }">
                {{ item.gameNumber }}
              </template>

              <template v-slot:item.transmission="{ item }">
                {{ item.transmissions.cod }}
              </template> -->

              <template v-slot:item.created="{ item }">
                {{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}
              </template> 

              <template slot="body.append">
                <tr class="">
                  <th>Total: {{ total | currency }}</th>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-container>
    </v-card>

    <!-- <modalJogos /> -->
  </Modal>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

import moment from 'moment'

// import validacao from './validacao'

// const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalTransmissoes',
  mixins: [validationMixin],
  components: {
    // modalJogos: () => import('./modalJogos'),
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    loadingModal: false,
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    data: null,
    revendedor: '',
    arrGames: [],
    total: '',
    headers: [
      { align: 'start', class: 'table-header', text: 'Terminal', value: 'entity' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'created' },
      { align: 'start', class: 'table-header', text: 'Código da Transmissão', value: 'codTransmissao' },
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'sweepstake.cod' },
      { align: 'start', class: 'table-header', text: 'Código do Bilhete', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' }
    ]
  }),
  
  computed: {
    // ...validacao,
    variables: () => variables,
  },
  mounted () {
    Events.$on('modal::descargaOnline::transmissao', (e) => {
      this.activeModal = true
      this.loadingModal = true

      this.total = e.transmissions[0].value

      this.arrGames = e.transmissions[0].games.map(item => {
        return {
          ...item,
          codTransmissao: e.transmissions[0].cod,
          entity: e.entity.text
        }
      })
    })
  },
  methods: {
    // ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas']),
    openModal () {
      this.activeModal = true
    },

    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::jogos', { val, data: this.data, revendedor: this.revendedor })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    formatDate (data) {
      if (data.hasMonthOrDate === 2) {
        this.dtInicial = moment(data.data, 'MM/YYYY').startOf('month').format('YYYY-MM-DD')
        this.dtFinal = moment(data.data, 'MM/YYYY').endOf('month').format('YYYY-MM-DD')

        return
      } 

      this.dtInicial = moment(data.data).format('YYYY-MM-DD')
      this.dtFinal = moment(data.data).format('YYYY-MM-DD')
     }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::transmissoes')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>